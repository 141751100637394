import { registerApp } from '@okapi/core';
import { initI18n, getDomain } from 'utils';
import { preloadedState, router, store } from 'store';
import { createEntry } from '../common';

if (preloadedState.isBot === false) {
  const domain = getDomain(preloadedState.host);
  initI18n(router.getPath(), domain);

  registerApp(__NAME__, () => createEntry({ store, router, remoteContext: {} }));
}
