import { createSelector } from 'reselect';
import { createFeatureSelector } from 'create-feature-selector';
import { RootState, Dispatch } from 'store';
import { getLang, getQaCookies, getHostname, getRegion } from 'utils';
import { CATALOGUE_PATH } from 'common/constants';
import { FetchCatalogueProps } from 'features/catalogue/types';
import { fetchCatalogueThunk } from './catalogue-slice';
import { GetCatalogueParams } from './api';

export const rootSelector = createFeatureSelector('catalogue');
export const getCatalogue = createSelector(rootSelector, (s) => s.data);
export const getCatalogueError = createSelector(rootSelector, (s) => s.isError);

export const fetchCatalogue = () => async (dispatch: Dispatch, getState: () => RootState) => {
  const state = getState();
  const hostname = getHostname(state.host || '');
  const apiKey = state.env.API_KEY;
  const qaCookie = getQaCookies(state.cookie);
  const lang = getLang();
  const region = getRegion(hostname);
  const params: FetchCatalogueProps = {
    region,
    path: CATALOGUE_PATH,
    maxNesting: '2',
    lang,
  };
  const options: GetCatalogueParams = {
    params,
  };

  if (qaCookie) {
    options.config = { headers: { cookie: qaCookie } };
  }

  if (apiKey) {
    options.config = { ...options.config, headers: { ...options.config?.headers, 'x-api-key': apiKey } };
  }

  return dispatch(fetchCatalogueThunk(options));
};
