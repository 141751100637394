import React, { FC } from 'react';
import { cx } from 'linaria';
import { useTranslation } from 'react-i18next';
import { Typography } from 'fronton-react';
import { ShockProduct } from 'features/page-model';
import { LOCALE_CODE } from 'common/constants';
import { getLang } from 'utils';
import {
  shockPrice,
  priceClassname,
  previousPriceWrapper,
  previousPriceClassname,
  previousPriceCurrency,
  uomClassname,
} from './styles';
import { getFormattedPrice } from './utils';

export const DATA_QA_SHOCK_PRODUCTS_PRICE = 'shock-product-price';
export const DATA_QA_SHOCK_PRODUCTS_PRICE_UOM = 'shock-product-price-uom';
export const DATA_QA_SHOCK_PRODUCTS_PRICE_CURRENT = 'shock-product-price-current';
export const DATA_QA_SHOCK_PRODUCTS_PRICE_PREVIOUS = 'shock-product-price-previous';

type Props = Pick<ShockProduct, 'uom' | 'price' | 'previousPrice'>;

export const Price: FC<Props> = ({ uom, price, previousPrice }) => {
  const { t } = useTranslation();
  const lang = getLang();
  const locale = LOCALE_CODE[lang];

  return (
    <div className={shockPrice} data-qa={DATA_QA_SHOCK_PRODUCTS_PRICE}>
      <div className={previousPriceWrapper} data-qa={DATA_QA_SHOCK_PRODUCTS_PRICE_PREVIOUS}>
        <Typography variant="m" size="subtitle" className={previousPriceClassname}>
          {getFormattedPrice(locale, previousPrice)}
        </Typography>
        <Typography
          variant="m"
          size="subtitle"
          className={cx(previousPriceClassname, previousPriceCurrency)}
        >
          {t('currency')}
        </Typography>
      </div>
      <div>
        <Typography
          variant="display"
          className={priceClassname}
          data-qa={DATA_QA_SHOCK_PRODUCTS_PRICE_CURRENT}
        >
          {getFormattedPrice(locale, price)}
        </Typography>
        &ensp;
        <Typography
          variant="m"
          size="subtitle"
          className={uomClassname}
          data-qa={DATA_QA_SHOCK_PRODUCTS_PRICE_UOM}
        >
          {t('currency')}/{uom}
        </Typography>
      </div>
    </div>
  );
};
