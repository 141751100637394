import { ElementType } from 'react';
import { styled } from 'linaria/react';
import { css } from 'linaria';
import { ContainerProps } from './carousel.types';

export const wrapperStyles = css`
  position: relative;
`;

export const Container: ElementType = styled.div<ContainerProps>`
  display: grid;
  justify-content: start;
  grid-auto-flow: column;
  grid-gap: var(--grid-gap);
  width: 100%;
  height: 100%;
  overflow: hidden;
  overscroll-behavior-x: contain;
  -webkit-overflow-scrolling: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  scroll-snap-type: ${({ inScrollNow }) => (inScrollNow ? 'none' : 'x mandatory')};
  &::-webkit-scrollbar {
    display: none;
  }
  overflow-x: scroll;
`;

export const SafariScrollGap = styled.div`
  width: 4px;
`;
