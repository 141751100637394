import React, { FC } from 'react';
import { cx } from 'linaria';
import { useBreakpoints, useOptimizedImages } from 'hooks';
import { image, mediaDesktop, mediaMobile, mediaMoreThenMobile, pictureContainer } from './styles';

export const DATA_QA_SHOCK_PICTURE = 'shock-price-product-picture';

type Props = {
  alt: string;
  src: string;
  className?: string;
};

const rests = {
  mobile: {
    w: '150',
    h: '150',
  },
  tablet: {
    w: '210',
    h: '210',
  },
  desktop: {
    w: '260',
    h: '260',
  },
};
export const Picture: FC<Props> = ({ alt, src, className }) => {
  const { mobile = false, tablet = false } = useBreakpoints() || {};
  const optimizedImage = useOptimizedImages(src, {
    mobile: rests.mobile,
    tablet: rests.tablet,
    desktop: rests.desktop,
  });

  const { width, height } = mobile
    ? { width: rests.mobile.w, height: rests.mobile.h }
    : tablet
      ? { width: rests.tablet.w, height: rests.tablet.h }
      : { width: rests.desktop.w, height: rests.desktop.h };

  return (
    <div className={cx(pictureContainer, className)}>
      <picture>
        <source
          media={mediaDesktop}
          srcSet={`${optimizedImage.desktop?.xx} 2x, ${optimizedImage.desktop?.x} 1x`}
        />
        <source
          media={mediaMoreThenMobile}
          srcSet={`${optimizedImage.tablet?.xx} 2x, ${optimizedImage.tablet?.x} 1x`}
        />
        <source
          media={mediaMobile}
          srcSet={`${optimizedImage.mobile?.xx} 2x, ${optimizedImage.mobile?.x} 1x`}
        />
        <img
          src={optimizedImage.desktop?.x || ''}
          srcSet={`${optimizedImage.desktop?.xx} 2x, ${optimizedImage.desktop?.x} 1x`}
          alt={alt}
          title={alt}
          className={image}
          loading="lazy"
          itemProp="image"
          data-qa={DATA_QA_SHOCK_PICTURE}
          width={width}
          height={height}
        />
      </picture>
    </div>
  );
};
